import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BIRole, userRoleToBIRole } from '../utils/bi';

const memberRoles: BIRole[] = ['owner', 'member'];

export const commonBIDataFromFlowAPI = (flowAPI: ControllerFlowAPI) => {
  const bi = flowAPI.controllerConfig.platformAPIs.bi;
  const user = flowAPI.controllerConfig.wixCodeApi.user.currentUser;
  const role = userRoleToBIRole(user.role);
  return {
    appInstanceId: flowAPI.controllerConfig.appParams.instanceId,
    metaSiteId: bi?.metaSiteId,
    biToken: bi?.biToken,
    role,
    site_member_id: bi?.siteMemberId ?? (memberRoles.includes(role) ? user.id : undefined),
    visitor_id: bi?.visitorId,
  };
};
